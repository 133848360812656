/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "ventilatie/ventilatie-5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "ventilatie/ventilatie-6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Gebalanceerde ventilatie met warmteterugwinning (WTW)`,
  textWithLink: () => (
    <>
      <p>
        Een gebalanceerd ventilatiesysteem is een ventilatiesysteem, waarbij een
        WTW-HR-unit is geplaatst voor de afvoer en luchttoevoer van vervuilde
        binnenlucht en verse buitenlucht.
      </p>
      <p>
        De afvoer van vervuilde binnenlucht geschiedt via ventilatiekanalen
        vanuit keuken, badkamer en/of toilet, via de ventilator naar buiten. De
        toevoer van verse buitenlucht geschiedt via ventilatiekanalen naar de
        woonkamer, slaapkamers en gangen.
      </p>
    </>
  ),
};

const textBottom = {
  header: `Twijfel je nog?`,
  textWithLink: () => (
    <>
      <p>
        E S I - Installatietechniek B.V. werkt met de meest voorkomende
        fabrikanten en kan daardoor altijd een passende installatie aanbieden
        voor een comfortabele luchtkwaliteit. Twijfel je nog? Twijfel dan niet
        om advies aan ons te vragen.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/ventilatie' className='ml-md-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Wtw = ({ data, path, location }) => {
  const seo = {
    title: `Warmteterugwinning`,
    description: `E S I - Installatietechniek B.V. werkt met de meest voorkomende fabrikanten en kan daardoor altijd een passende installatie aanbieden voor een comfortabele luchtkwaliteit.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Hoe werkt warmteterugwinning?',
    inner:
      'In het warmteterugwinapparaat wordt d.m.v een tegenstroomwisselaar warmte overgedragen tussen de afvoerlucht en de toevoerlucht. Hierbij kan een temperatuur rendement van >95% gerealiseerd worden. Op deze wijze wordt een aanzienlijke energiebesparing gerealiseerd.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Waaruit bestaat een warmteterugwinning?',
    textWithLink: () => (
      <>
        <p>
          Een WTW-unit is voorzien van 2 filters. Voordat de frisse buitenlucht
          via het WTW apparaat de woning wordt ingeblazen, wordt deze eerst
          gefilterd. De afvoerlucht wordt eerst gefilterd, voordat het de
          tegenstroomwisselaar bereikt. Dit ter bescherming van deze
          tegenstroomwisselaar.
        </p>
        <h3>Welke keuze heb ik?</h3>
        <p>
          Tegenwoordig zijn er verschillende uitvoeringen van deze gebalanceerde
          ventilatiesystemen. Zo is het mogelijk meerdere draadloze schakelaars
          toe te passen, of te regelen op vocht, CO2 en aan/afwezigheid.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw WTW-installaties`,
    pitchLine: `Laat al uw warmteterugwinning over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Wtw.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Wtw;
